import React from 'react'
import { RouteObject } from 'react-router'
import { HomePage } from 'pages/home/Home'
import { AppLayout } from 'layouts/AppLayout/AppLayout'
import { AboutPage } from 'pages/about/About'
import { NotFound } from 'pages/error/NotFound'
import { PartnersPage } from 'pages/partners/Partners'
import { ContactPage } from 'pages/contact/Contact'
import { TermsAndConditionsPage } from 'pages/termsAndConditions/TermsAndConditions'
import { PrivacyPolicy } from 'pages/privacyPolicy/PrivacyPolicy'
import { PartnersJoinPage } from 'pages/partners/PartnersJoin'
import { blogRoutes } from 'pages/blog/blog.routes'

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <AppLayout />,
    errorElement: <NotFound />,
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
      {
        path: '/about',
        element: <AboutPage />,
      },
      {
        path: '/partners',
        element: <PartnersPage />,
      },
      {
        path: '/partners/join',
        element: <PartnersJoinPage />,
      },
      {
        path: '/contact',
        element: <ContactPage />,
      },
      {
        path: '/terms-and-conditions',
        element: <TermsAndConditionsPage />,
      },
      {
        path: '/privacy-policy',
        element: <PrivacyPolicy />,
      },
      ...blogRoutes,
    ],
  },
]
