import React from 'react'

import { Box, Container, Divider, Grid, Typography } from '@mui/material'
import { Logo } from 'components/logo/Logo'
import { FooterNav } from 'layouts/AppLayout/Footer/FooterNav'
import { FooterBottom } from 'layouts/AppLayout/Footer/FooterBottom'
import { useTranslation } from 'react-i18next'

export const Footer: React.FC = () => {
  const { t } = useTranslation('footer')
  return (
    <Box component="footer" sx={{ mt: 10 }}>
      <Divider />
      <Container sx={{ pt: 10, pb: 5 }}>
        <Grid
          container
          justifyContent={{
            xs: 'center',
            sm: 'space-between',
          }}
        >
          <Grid item xs={12} sm={4}>
            <Logo sx={{ mb: 3 }} />
            <Typography
              variant="body2"
              sx={{
                maxWidth: 400,
                mx: { xs: 'auto', sm: 'unset' },
              }}
            >
              {t('companyDescription')}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mt: { xs: 3, sm: 0 } }}>
            <FooterNav />
          </Grid>
        </Grid>
        <FooterBottom />
      </Container>
    </Box>
  )
}
