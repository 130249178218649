import React from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { PageLayout } from 'layouts/PageLayout/PageLayout'
import { Link as RouterLink } from 'react-router-dom'
import { PageSection } from 'components/page/PageSection/PageSection'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

export const AboutPage: React.FC = () => {
  const { t } = useTranslation('about')
  return (
    <PageLayout title={t('about-MockPhish-title')}>
      <Helmet>
        <title>Over MockPhish | MockPhish</title>
        <meta
          name="description"
          content="MockPhish biedt superieure phishing simulatie tools voor MSP's en MSSP's, met een focus op eenvoudige, krachtige software en uitstekende klantenondersteuning."
        />
        <meta name="keywords" content="phishing simulatie, cybersecurity, MSP, MSSP, netwerkbeveiliging, MockPhish" />
        <meta name="author" content="MockPhish" />
        <meta property="og:title" content="Over MockPhish | MockPhish" />
        <meta
          property="og:description"
          content="MockPhish biedt superieure phishing simulatie tools voor MSP's en MSSP's, met een focus op eenvoudige, krachtige software en uitstekende klantenondersteuning."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.mockphish.io/about" />
        <meta property="og:image" content="https://mockphish.io/logo-black.png" />
      </Helmet>

      <PageSection>
        <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
          {t('about-MockPhish-text')}
        </Typography>
      </PageSection>

      <PageSection title={t('our-key-values-title')} titleAlign={{ sx: 'start', sm: 'center' }} alternative={true}>
        <Stack spacing={5} direction={{ xs: 'column', sm: 'row' }} alignItems="cetner" sx={{ mt: 2 }}>
          <Stack spacing={2}>
            <Typography variant="h6">{t('key-value-1-title')}</Typography>
            <Typography variant="body1">{t('key-value-1-description')}</Typography>
          </Stack>

          <Box
            component="img"
            src="/icons/icon-key.png"
            alt="Icon"
            sx={{ width: '200px', display: { xs: 'none', md: 'block' } }}
          />

          <Stack spacing={2}>
            <Typography variant="h6">{t('key-value-2-title')}</Typography>
            <Typography variant="body1">{t('key-value-2-description')}</Typography>
          </Stack>
        </Stack>
      </PageSection>

      <PageSection title={t('know-more-title')}>
        <Typography variant="body1">{t('know-more-description')}</Typography>
        <Box>
          <Button component={RouterLink} to="/contact" size="large" variant="contained" color="primary">
            {t('know-more-button-text')}
          </Button>
        </Box>
      </PageSection>
    </PageLayout>
  )
}
