import React, { useEffect } from 'react'

import { SettingsProvider, useSettingsContext } from 'hooks/useSettings'
import ThemeProvider from 'theme'

import './i18n'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { routes } from 'app.routes'
import { SnackbarProvider } from 'components/snackbar/SnackbarProvider'
import { initializeAnalytics } from 'helpers/analytics'

const router = createBrowserRouter(routes)

const App: React.FC = () => {
  const settingsContext = useSettingsContext()

  useEffect(() => {
    initializeAnalytics()
  }, [])

  return (
    <SettingsProvider value={settingsContext}>
      <ThemeProvider>
        <SnackbarProvider>
          <RouterProvider router={router} />
        </SnackbarProvider>
      </ThemeProvider>
    </SettingsProvider>
  )
}

export default App
