import React from 'react'
import { Box, Button, Card, Stack, Typography, Grid } from '@mui/material'
import { PageLayout } from 'layouts/PageLayout/PageLayout'
import { Link as RouterLink } from 'react-router-dom'
import { PageSection } from 'components/page/PageSection/PageSection'
import { SettingsSuggest, SupervisedUserCircle, AddBusiness } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

export const PartnersPage: React.FC = () => {
  const { t } = useTranslation('partners')
  const renderCard = (icon: React.ReactNode, title: string, description: string) => (
    <Grid item xs={12} sm={4} key={title}>
      <Card sx={{ p: 5, height: '100%' }}>
        <Stack spacing={2}>
          {icon}
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body1" color="text.secondary">
            {description}
          </Typography>
        </Stack>
      </Card>
    </Grid>
  )

  const reasons = [
    {
      icon: <SettingsSuggest sx={{ fontSize: 40 }} color="primary" />,
      title: t('reason-1-innovation-title'),
      description: t('reason-1-innovation-description'),
    },
    {
      icon: <SupervisedUserCircle sx={{ fontSize: 40 }} color="primary" />,
      title: t('reason-2-support-title'),
      description: t('reason-2-support-description'),
    },
    {
      icon: <AddBusiness sx={{ fontSize: 40 }} color="primary" />,
      title: t('reason-3-marketGrowth-title'),
      description: t('reason-3-marketGrowth-description'),
    },
  ]

  return (
    <PageLayout title={t('partners-title')}>
      <Helmet>
        <title>Partners | MockPhish</title>
        <meta
          name="description"
          content="Word een partner van MockPhish en help organisaties beschermen tegen phishingaanvallen met onze innovatieve phishing simulatie tools en uitgebreide ondersteuning."
        />
        <meta name="keywords" content="partners, phishing simulatie, cybersecurity, MSP, MSSP, IT-providers, MockPhish" />
        <meta name="author" content="MockPhish" />
        <meta property="og:title" content="Partners | MockPhish" />
        <meta
          property="og:description"
          content="Word een partner van MockPhish en help organisaties beschermen tegen phishingaanvallen met onze innovatieve phishing simulatie tools en uitgebreide ondersteuning."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.mockphish.io/partners" />
        <meta property="og:image" content="https://mockphish.io/logo-black.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Partners | MockPhish" />
        <meta
          name="twitter:description"
          content="Word een partner van MockPhish en help organisaties beschermen tegen phishingaanvallen met onze innovatieve phishing simulatie tools en uitgebreide ondersteuning."
        />
        <meta name="twitter:url" content="https://www.mockphish.io/partners" />
        <meta name="twitter:image" content="https://mockphish.io/logo-black.png" />
      </Helmet>

      <PageSection>
        <Typography variant="body1">{t('partners-description')}</Typography>
      </PageSection>

      <PageSection title={t('reasons-title')} alternative={true}>
        <Grid container spacing={5}>
          {reasons.map((reason) => renderCard(reason.icon, reason.title, reason.description))}
        </Grid>
      </PageSection>

      <PageSection title={t('becomeAPartner-title')}>
        <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
          {t('becomeAPartner-description')}
        </Typography>
        <Box>
          <Button component={RouterLink} to="/partners/join" size="large" variant="contained" color="primary">
            {t('becomeAPartner-button-text')}
          </Button>
        </Box>
      </PageSection>
    </PageLayout>
  )
}
