import React from 'react'
import { Link, Stack, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const FOOTER_LINKS = [
  {
    label: 'mockphish',
    children: [
      { name: 'about', href: '/about' },
      { name: 'partners', href: '/partners' },
      { name: 'blog', href: '/blog' },
      { name: 'contact', href: '/contact' },
    ],
  },
  {
    label: 'general',
    children: [
      { name: 'termsAndConditions', href: '/terms-and-conditions' },
      { name: 'privacyPolicy', href: '/privacy-policy' },
    ],
  },
  // {
  //   label: 'Support',
  //   children: [
  //     { name: 'Helpdesk', href: '/contact' },
  //     { name: 'FAQ', href: '/faq' },
  //   ],
  // },
]

export const FooterNav: React.FC = () => {
  const { t } = useTranslation('footer')
  return (
    <Stack direction="row" spacing={5}>
      {FOOTER_LINKS.map((list) => (
        <Stack spacing={2} alignItems={{ xs: 'center', md: 'flex-start' }} sx={{ width: 1 }} key={list.label}>
          <Typography component="div" variant="overline">
            {t(`navLabel-${list.label}`)}
          </Typography>

          {list.children.map((link) => (
            <Link key={link.name} component={RouterLink} to={link.href} color="inherit" variant="body2">
              {t(`navItem-${link.name}`)}
            </Link>
          ))}
        </Stack>
      ))}
    </Stack>
  )
}
