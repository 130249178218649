import React from 'react'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import { useSettings } from 'hooks/useSettings'
import { HEADER } from 'theme/constants'
import { useResponsive } from 'hooks/useResponsive'
import { useTranslation } from 'react-i18next'

export const HomeHero: React.FC = () => {
  const { settings } = useSettings()
  const { themeMode } = settings
  const lgUp = useResponsive('up', 'lg')
  const mdUp = useResponsive('up', 'md')

  const { t } = useTranslation('home')

  return (
    <Box
      sx={{
        backgroundColor: themeMode === 'dark' ? '#184171' : '#a4c0ef',
        clipPath: 'polygon(0 0, 100% 0, 100% 96%, 0 100%)',
        pt: `${HEADER.H_DESKTOP}px`,
      }}
    >
      <Container>
        <Stack alignItems="center" justifyContent="center" direction={{ xs: 'column-reverse', md: 'row' }} sx={{ py: 5 }}>
          <Stack spacing={2} alignItems={lgUp ? 'flex-start' : 'center'}>
            <Typography variant="h1" textAlign={mdUp ? 'start' : 'center'}>
              {t('hero-slogan')}
            </Typography>
            <Typography variant="body1" textAlign={mdUp ? 'start' : 'center'}>
              {t('hero-subTitle')}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() =>
                window.scrollTo({ top: (document.getElementById('info')?.offsetTop || 0) - HEADER.H_DESKTOP, behavior: 'smooth' })
              }
            >
              {t('discoverMore')}
            </Button>
          </Stack>
          <Box
            component="img"
            src="/icons/icon-phishing.png"
            alt="Phishing icon"
            sx={{
              width: {
                xs: '70vw',
                sm: '50vw',
                md: '35vw',
              },
              ml: {
                xs: 0,
                lg: '-5rem',
              },
            }}
          />
        </Stack>
      </Container>
    </Box>
  )
}
