import { createProvider } from 'helpers/createProvider'
import { useLocalStorage } from 'hooks/useLocalStorage'

type ThemeMode = 'light' | 'dark'

export type SettingsContext = {
  settings: {
    themeMode: ThemeMode
  }
  changeThemeMode: (newThemeMode: ThemeMode) => void
}

export const useSettingsContext = (): SettingsContext => {
  const [themeMode, setThemeMode] = useLocalStorage<ThemeMode>('themeMode', 'light')

  const changeThemeMode = (newValue: ThemeMode) => setThemeMode(newValue)

  return {
    settings: {
      themeMode: themeMode!,
    },
    changeThemeMode,
  }
}

const { Provider, useProvider } = createProvider<SettingsContext>()
export const SettingsProvider = Provider
export const useSettings = useProvider
