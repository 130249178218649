import React from 'react'
import { Home, Groups, Handshake, ContactSupport, Book } from '@mui/icons-material'

export const navConfig = [
  {
    title: 'home',
    icon: <Home />,
    path: '/',
  },
  {
    title: 'about',
    icon: <Groups />,
    path: '/about',
  },
  {
    title: 'forPartners',
    icon: <Handshake />,
    path: '/partners',
  },
  {
    title: 'blog',
    icon: <Book />,
    path: '/blog',
  },
  {
    title: 'contact',
    icon: <ContactSupport />,
    path: '/contact',
  },
]
