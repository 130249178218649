import React, { forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import Link from '@mui/material/Link'
import { ExpandMore } from '@mui/icons-material'

import { NavItemProps } from '../types'
import { StyledNavItem } from './NavItemStyles'
import { useTranslation } from 'react-i18next'

export const NavItem = forwardRef<HTMLDivElement, NavItemProps>(function NavItem(
  { title, path, open, active, hasChild, externalLink, subItem, ...other },
  ref
) {
  const { t } = useTranslation('header')
  const renderContent = (
    <StyledNavItem disableRipple disableTouchRipple ref={ref} open={open} active={active} subItem={subItem} {...other}>
      {t(title)}

      {hasChild && <ExpandMore sx={{ fontSize: 16, ml: 1 }} />}
    </StyledNavItem>
  )

  if (hasChild) {
    return renderContent
  }

  if (externalLink) {
    return (
      <Link href={path} target="_blank" rel="noopener" color="inherit" underline="none">
        {renderContent}
      </Link>
    )
  }

  return (
    <Link component={RouterLink} to={path} color="inherit" underline="none">
      {renderContent}
    </Link>
  )
})
