import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import nlTranslations from './nl'
import enTranslations from './en'
import { DEFAULT_LANGUAGE } from 'i18n/useLocales'

const storedLanguage = localStorage.getItem('language') || DEFAULT_LANGUAGE.value

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(initReactI18next).init({
  resources: {
    nl: nlTranslations,
    en: enTranslations,
  },
  lng: storedLanguage,
  interpolation: {
    escapeValue: false, // react already safes from xss,
    format: (value: any, format: string | undefined) => {
      switch (format) {
        case 'lowercase':
          return value.toLowerCase()
        default:
          return value.toString()
      }
    },
  },
})
