import { enqueueSnackbar } from 'notistack'

type SnackbarService = {
  showSuccess: (message: string) => void
  showWarning: (message: string) => void
  showInfo: (message: string) => void
  showError: (message: string) => void
}

export const useSnackbar: () => SnackbarService = () => {
  return {
    showSuccess: (message: string) => enqueueSnackbar(message, { variant: 'success' }),
    showWarning: (message: string) => enqueueSnackbar(message, { variant: 'warning' }),
    showInfo: (message: string) => enqueueSnackbar(message, { variant: 'info' }),
    showError: (message: string) => enqueueSnackbar(message, { variant: 'error' }),
  }
}
