import React from 'react'
import { Box } from '@mui/material'
import { useSettings } from 'hooks/useSettings'
import { SxProps } from '@mui/material/styles'

type LogoProps = {
  sx?: SxProps
}

export const Logo: React.FC<LogoProps> = ({ sx }) => {
  const { settings } = useSettings()
  const themeMode = settings.themeMode

  return (
    <Box
      component="img"
      src={themeMode === 'dark' ? '/logo-white.png' : '/logo-black.png'}
      alt="Logo"
      sx={{ height: 30, display: 'inline-flex', objectFit: 'contain', ...sx }}
    />
  )
}
