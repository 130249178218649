import React from 'react'
import { useTranslation } from 'react-i18next'
import { PageLayout } from 'layouts/PageLayout/PageLayout'
import posts from './posts'
import { Card, CardContent, Container, Grid, Link, Typography, Button, Stack, Box } from '@mui/material'
import { Image } from 'components/image/Image'
import { formatDate } from 'util/dateUtil'
import { Link as RouterLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export const BlogOverview: React.FC = () => {
  const { t } = useTranslation('blog')

  const publishedPosts = posts.filter((p) => p.public)

  return (
    <PageLayout title={t('blog-title')}>
      <Helmet>
        <title>Blog | MockPhish</title>
        <meta
          name="description"
          content="Blijf op de hoogte van de nieuwste trends en inzichten op het gebied van cybersecurity met de MockPhish blog. Ontdek waardevolle artikelen over phishing simulaties en meer."
        />
        <meta name="keywords" content="blog, phishing simulatie, cybersecurity, MSP, MSSP, MockPhish" />
        <meta name="author" content="MockPhish" />
        <meta property="og:title" content="Blog | MockPhish" />
        <meta
          property="og:description"
          content="Blijf op de hoogte van de nieuwste trends en inzichten op het gebied van cybersecurity met de MockPhish blog. Ontdek waardevolle artikelen over phishing simulaties, netwerkbeveiliging, en meer."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.mockphish.io/blog" />
        <meta property="og:image" content="https://mockphish.io/logo-black.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Blog | MockPhish" />
        <meta
          name="twitter:description"
          content="Blijf op de hoogte van de nieuwste trends en inzichten op het gebied van cybersecurity met de MockPhish blog. Ontdek waardevolle artikelen over phishing simulaties, netwerkbeveiliging, en meer."
        />
        <meta name="twitter:url" content="https://www.mockphish.io/blog" />
        <meta name="twitter:image" content="https://mockphish.io/logo-black.png" />
      </Helmet>
      <Container sx={{ mt: 5 }}>
        <Grid container spacing={3}>
          {publishedPosts.map((post) => (
            <Grid item key={post.id} xs={12} sm={4}>
              <Card sx={{ height: '100%' }}>
                <Image alt={post.showcaseImageAlt} src={post.showcaseImage} ratio="16/9" />

                <CardContent
                  sx={{
                    pt: 3,
                    width: 1,
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    sx={{
                      mb: 1,
                      color: 'text.disabled',
                    }}
                  >
                    {formatDate(post.date)}
                  </Typography>

                  <Link color="inherit" component={RouterLink} to={post.id}>
                    <Typography variant="subtitle2">{post.title}</Typography>
                  </Link>
                </CardContent>
              </Card>
            </Grid>
          ))}

          <Grid item xs={12} sm={4}>
            <Card sx={{ height: 1 }}>
              <CardContent
                sx={{
                  pt: 3,
                  width: 1,
                  height: 1,
                }}
              >
                <Stack justifyContent="center" alignItems="flex-start" sx={{ height: 1 }}>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h4">Meer weten?</Typography>
                    <Typography variant="body1">
                      Wil je meer weten over onze diensten en hoe we jouw bedrijf kunnen ondersteunen? Neem gerust contact met ons
                      op.
                    </Typography>
                  </Box>
                  <Button variant="outlined" color="primary" component={RouterLink} to="/contact">
                    Neem contact op
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </PageLayout>
  )
}
