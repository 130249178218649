import React, { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'
import Portal from '@mui/material/Portal'
import { useTheme } from '@mui/material/styles'

import { useActiveLink } from 'hooks/useActiveLink'

import { paper } from 'theme/css'

import { HEADER } from 'theme/constants'
import { NavItem } from './NavItem'
import { NavSubList } from './NavSubList'
import { NavListProps } from '../types'

export const NavList: React.FC<NavListProps> = ({ data }) => {
  const theme = useTheme()
  const { pathname } = useLocation()
  const active = useActiveLink(data.path, !!data.children)

  const [openMenu, setOpenMenu] = useState(false)

  const handleOpenMenu = useCallback(() => {
    if (data.children) {
      setOpenMenu(true)
    }
  }, [data.children])

  const handleCloseMenu = useCallback(() => {
    setOpenMenu(false)
  }, [])

  useEffect(() => {
    if (openMenu) {
      handleCloseMenu()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <>
      <NavItem
        open={openMenu}
        onMouseEnter={handleOpenMenu}
        onMouseLeave={handleCloseMenu}
        //
        title={data.title}
        path={data.path}
        //
        hasChild={!!data.children}
        externalLink={data.path.includes('http')}
        //
        active={active}
      />

      {!!data.children && openMenu && (
        <Portal>
          <Fade in={openMenu}>
            <Paper
              onMouseEnter={handleOpenMenu}
              onMouseLeave={handleCloseMenu}
              sx={{
                ...paper({ theme }),
                left: 0,
                right: 0,
                m: 'auto',
                display: 'flex',
                borderRadius: 2,
                position: 'fixed',
                zIndex: theme.zIndex.modal,
                p: theme.spacing(5, 1, 1, 3),
                top: HEADER.H_DESKTOP_OFFSET,
                maxWidth: theme.breakpoints.values.lg,
                boxShadow: theme.customShadows.dropdown,
              }}
            >
              {data.children.map((list) => (
                <NavSubList key={list.subheader} subheader={list.subheader} data={list.items} />
              ))}
            </Paper>
          </Fade>
        </Portal>
      )}
    </>
  )
}
