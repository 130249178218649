import { useState, useEffect } from 'react'
import { localStorage } from 'helpers/storage'

type UseLocalStorageReturn<T> = [T | undefined, (newValue: T) => void, () => void]
export const useLocalStorage = <T>(key: string, initialState?: T): UseLocalStorageReturn<T> => {
  const [value, setValue] = useState<T | undefined>(initialState)

  useEffect(() => {
    const restored = localStorage.get(key) as T
    if (restored) setValue(restored)
  }, [])

  const updateValue = (newValue: T) => {
    setValue(newValue)
    localStorage.set(key, newValue)
  }

  const deleteValue = () => {
    setValue(undefined)
    localStorage.delete(key)
  }

  return [value, updateValue, deleteValue]
}
