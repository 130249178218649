import React from 'react'
import { RouteObject } from 'react-router'
import { BlogOverview } from 'pages/blog/BlogOverview'
import { BlogPost } from 'pages/blog/BlogPost'

export const blogRoutes: RouteObject[] = [
  {
    path: '/blog',
    element: <BlogOverview />,
  },
  {
    path: '/blog/:postId',
    element: <BlogPost />,
  },
]
