import React, { useMemo } from 'react'
import merge from 'lodash/merge'

import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeOptions, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'

// system
import { palette } from './palette'
import { shadows } from './shadows'
import { typography } from './typography'
// options
import { customShadows } from './custom-shadows'
import { componentsOverrides } from './overrides'
import { createPresets } from './options/presets'
import { createContrast } from './options/contrast'
import { useSettings } from 'hooks/useSettings'

type Props = {
  children: React.ReactNode
}

export default function ThemeProvider({ children }: Props) {
  const { settings } = useSettings()

  const presets = createPresets()
  const contrast = createContrast()

  const memoizedValue = useMemo(
    () => ({
      palette: {
        ...palette(settings.themeMode),
        ...presets.palette,
        ...contrast.palette,
      },
      customShadows: {
        ...customShadows(settings.themeMode),
        ...presets.customShadows,
      },
      shadows: shadows(settings.themeMode),
      shape: { borderRadius: 8 },
      typography,
    }),
    [settings.themeMode, presets.palette, presets.customShadows, contrast.palette]
  )

  const theme = createTheme(memoizedValue as ThemeOptions)

  theme.components = merge(componentsOverrides(theme), contrast.components)

  const themeWithLocale = useMemo(() => createTheme(theme), [theme])

  return (
    <MuiThemeProvider theme={themeWithLocale}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  )
}
