import React from 'react'
import { Typography } from '@mui/material'
import { PageLayout } from 'layouts/PageLayout/PageLayout'
import { PageSection } from 'components/page/PageSection/PageSection'
import { Helmet } from 'react-helmet'

export const TermsAndConditionsPage: React.FC = () => {
  return (
    <PageLayout title="Algemene voorwaarden">
      <Helmet>
        <title>Algemene Voorwaarden | MockPhish</title>
        <meta
          name="description"
          content="Lees de algemene voorwaarden van MockPhish om te begrijpen welke regels en richtlijnen van toepassing zijn op het gebruik van onze diensten."
        />
        <meta name="keywords" content="algemene voorwaarden, gebruiksvoorwaarden, MockPhish" />
        <meta name="author" content="MockPhish" />
        <meta property="og:title" content="Algemene Voorwaarden | MockPhish" />
        <meta
          property="og:description"
          content="Lees de algemene voorwaarden van MockPhish om te begrijpen welke regels en richtlijnen van toepassing zijn op het gebruik van onze diensten."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.mockphish.io/terms-and-conditions" />
        <meta property="og:image" content="https://mockphish.io/logo-black.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Algemene Voorwaarden | MockPhish" />
        <meta
          name="twitter:description"
          content="Lees de algemene voorwaarden van MockPhish om te begrijpen welke regels en richtlijnen van toepassing zijn op het gebruik van onze diensten."
        />
        <meta name="twitter:url" content="https://www.mockphish.io/terms-and-conditions" />
        <meta name="twitter:image" content="https://mockphish.io/logo-black.png" />
      </Helmet>
      <PageSection>
        <Typography variant="body1">
          Welkom bij MockPhish. Hieronder vindt u de algemene voorwaarden die van toepassing zijn op het gebruik van onze website
          en onze diensten. Door gebruik te maken van onze website en diensten, gaat u akkoord met deze voorwaarden. Neem deze
          voorwaarden zorgvuldig door voordat u gebruik maakt van onze diensten.
        </Typography>
        <Typography variant="h6">1. Definities</Typography>
        <ul>
          <li>
            <strong>MockPhish:</strong> Verwijst naar MockPhish, het bedrijf dat het phishing simulatieplatform aanbiedt.
          </li>
          <li>
            <strong>Gebruiker:</strong> Verwijst naar een persoon of entiteit die gebruik maakt van de diensten van MockPhish.
          </li>
          <li>
            <strong>Diensten:</strong> Verwijst naar de diensten die MockPhish aanbiedt, inclusief maar niet beperkt tot het
            phishing simulatieplatform en gerelateerde services.
          </li>
          <li>
            <strong>Website:</strong> Verwijst naar de website van MockPhish, toegankelijk via{' '}
            <a href="www.mockphish.io">www.mockphish.io</a>.
          </li>
        </ul>

        <Typography variant="h6">2. Gebruik van Onze Diensten</Typography>
        <Typography variant="body1">
          Door gebruik te maken van onze diensten stemt u ermee in dat u de diensten alleen zult gebruiken voor legale doeleinden
          en in overeenstemming met deze algemene voorwaarden. U mag de diensten niet gebruiken op een manier die schade kan
          veroorzaken aan MockPhish, onze diensten of aan andere gebruikers.
        </Typography>

        <Typography variant="h6">3. Account Registratie</Typography>
        <Typography variant="body1">
          Om toegang te krijgen tot bepaalde delen van onze diensten, kan het zijn dat u een account moet registreren bij
          MockPhish. U bent verantwoordelijk voor het handhaven van de vertrouwelijkheid van uw accountgegevens en voor alle
          activiteiten die plaatsvinden onder uw account.
        </Typography>

        <Typography variant="h6">4. Intellectuele Eigendom</Typography>
        <Typography variant="body1">
          Alle inhoud op onze website en binnen onze diensten, inclusief maar niet beperkt tot tekst, afbeeldingen, logo&apos;s,
          software en ontwerpen, is eigendom van MockPhish of onze licentiegevers. Het is niet toegestaan om zonder voorafgaande
          schriftelijke toestemming van MockPhish onze intellectuele eigendom te kopiëren, wijzigen, verspreiden, verkopen of
          gebruiken op een manier die niet uitdrukkelijk is toegestaan door deze voorwaarden.
        </Typography>

        <Typography variant="h6">5. Beperking van Aansprakelijkheid</Typography>
        <Typography variant="body1">
          MockPhish biedt onze diensten aan op een &quot;as is&quot; basis. We bieden geen garanties met betrekking tot de
          beschikbaarheid, nauwkeurigheid, betrouwbaarheid of veiligheid van onze diensten. MockPhish is niet aansprakelijk voor
          directe, indirecte, incidentele, bijzondere of gevolgschade die voortvloeit uit het gebruik van onze diensten.
        </Typography>

        <Typography variant="h6">6. Wijzigingen aan deze Voorwaarden</Typography>
        <Typography variant="body1">
          MockPhish behoudt zich het recht voor om deze algemene voorwaarden op elk moment en naar eigen goeddunken te wijzigen.
          Wijzigingen aan deze voorwaarden zullen effectief zijn op het moment dat ze worden gepubliceerd op onze website. Het is
          uw verantwoordelijkheid om regelmatig deze voorwaarden te controleren op updates.
        </Typography>

        <Typography variant="h6">7. Toepasselijk Recht</Typography>
        <Typography variant="body1">
          Deze algemene voorwaarden worden beheerst door en geïnterpreteerd in overeenstemming met de Nederlandse wetgeving.
        </Typography>

        <Typography variant="h6">Contact</Typography>
        <Typography variant="body1">
          Als u vragen heeft over deze algemene voorwaarden, neem dan contact met ons op via contact@mockphish.io
        </Typography>

        <Typography variant="body2">Laatst bijgewerkt: 29-06-2024</Typography>
      </PageSection>
    </PageLayout>
  )
}
