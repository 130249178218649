import React from 'react'
import { Typography } from '@mui/material'
import { PageLayout } from 'layouts/PageLayout/PageLayout'
import { PageSection } from 'components/page/PageSection/PageSection'
import { Helmet } from 'react-helmet'

export const PrivacyPolicy: React.FC = () => {
  return (
    <PageLayout title="Privacybeleid">
      <Helmet>
        <title>Privacybeleid | MockPhish</title>
        <meta
          name="description"
          content="Lees het privacybeleid van MockPhish om te begrijpen hoe wij uw persoonlijke gegevens verzamelen, gebruiken en beschermen."
        />
        <meta name="keywords" content="privacybeleid, privacy, gegevensbescherming, MockPhish" />
        <meta name="author" content="MockPhish" />
        <meta property="og:title" content="Privacybeleid | MockPhish" />
        <meta
          property="og:description"
          content="Lees het privacybeleid van MockPhish om te begrijpen hoe wij uw persoonlijke gegevens verzamelen, gebruiken en beschermen."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.mockphish.io/privacy-policy" />
        <meta property="og:image" content="https://mockphish.io/logo-black.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Privacybeleid | MockPhish" />
        <meta
          name="twitter:description"
          content="Lees het privacybeleid van MockPhish om te begrijpen hoe wij uw persoonlijke gegevens verzamelen, gebruiken en beschermen."
        />
        <meta name="twitter:url" content="https://www.mockphish.io/privacy-policy" />
        <meta name="twitter:image" content="https://mockphish.io/logo-black.png" />
      </Helmet>
      <PageSection>
        <Typography variant="body1">
          Welkom bij MockPhish. Hieronder vindt u onze privacy policy die beschrijft hoe wij persoonlijke informatie verzamelen,
          gebruiken en beschermen. Door gebruik te maken van onze website en diensten, stemt u in met ons privacybeleid. Neem dit
          beleid zorgvuldig door voordat u persoonlijke informatie met ons deelt.
        </Typography>

        <Typography variant="h6">1. Verzameling van Persoonlijke Informatie</Typography>
        <Typography variant="body1">
          MockPhish verzamelt persoonlijke informatie die u vrijwillig aan ons verstrekt bij het registreren van een account, het
          gebruik van onze diensten, of het communiceren met ons via onze website of andere kanalen. Deze informatie kan onder
          meer uw naam, e-mailadres, telefoonnummer en bedrijfsgegevens bevatten.
        </Typography>

        <Typography variant="h6">2. Gebruik van Persoonlijke Informatie</Typography>
        <Typography variant="body1">
          De persoonlijke informatie die wij verzamelen, wordt gebruikt om u toegang te bieden tot onze diensten, uw account te
          beheren, u op de hoogte te houden van updates of belangrijke informatie, en om onze diensten te verbeteren en aan te
          passen aan uw behoeften.
        </Typography>

        <Typography variant="h6">3. Delen van Persoonlijke Informatie</Typography>
        <Typography variant="body1">
          MockPhish deelt uw persoonlijke informatie alleen met derden zoals vereist door de wet, om onze diensten te leveren of
          te verbeteren, of met uw uitdrukkelijke toestemming.
        </Typography>

        <Typography variant="h6">4. Beveiliging van Persoonlijke Informatie</Typography>
        <Typography variant="body1">
          Wij nemen redelijke maatregelen om uw persoonlijke informatie te beschermen tegen ongeautoriseerde toegang, gebruik,
          openbaarmaking of vernietiging. Echter, geen enkele methode van overdracht via internet of elektronische opslag is 100%
          veilig.
        </Typography>

        <Typography variant="h6">5. Cookies en Tracking Technologieën</Typography>
        <Typography variant="body1">
          MockPhish maakt gebruik van cookies en vergelijkbare tracking technologieën om de gebruikerservaring te verbeteren, onze
          diensten te analyseren en te personaliseren. U kunt uw browser instellen om cookies te weigeren, maar dit kan van
          invloed zijn op de functionaliteit van onze website.
        </Typography>

        <Typography variant="h6">6. Wijzigingen aan dit Privacybeleid</Typography>
        <Typography variant="body1">
          MockPhish behoudt zich het recht voor om dit privacybeleid op elk moment te wijzigen. Wijzigingen aan dit beleid worden
          van kracht op het moment dat ze op onze website worden gepubliceerd.
        </Typography>

        <Typography variant="h6">7. Contact</Typography>
        <Typography variant="body1">
          Als u vragen heeft over ons privacybeleid, neem dan contact met ons op via{' '}
          <a href="mailto:contact@mockphish.io">contact@mockphish.io</a>.
        </Typography>

        <Typography variant="body2">Laatst bijgewerkt: 29-06-2024</Typography>
      </PageSection>
    </PageLayout>
  )
}
