import { useLocation } from 'react-router-dom'

export const useActiveLink = (path: string, deep = true): boolean => {
  const { pathname } = useLocation()

  const checkPath = path.startsWith('#')
  const currentPath = path

  const normalActive = !checkPath && pathname === currentPath
  const deepActive = !checkPath && pathname.includes(currentPath)

  return deep ? deepActive : normalActive
}
