import React, { PropsWithChildren } from 'react'
import { Box, Container, Stack, Typography, TypographyProps } from '@mui/material'

type PageSectionProps = PropsWithChildren<{
  title?: string
  titleAlign?: TypographyProps['textAlign']
  alternative?: boolean
}>

export const PageSection: React.FC<PageSectionProps> = ({ title, alternative = false, titleAlign, children }) => {
  return (
    <Box
      sx={{ background: (theme) => (alternative ? theme.palette.background.neutral : theme.palette.background.default), py: 7 }}
    >
      <Container>
        <Stack spacing={2}>
          {title && (
            <Typography variant="h3" textAlign={titleAlign!}>
              {title}
            </Typography>
          )}
          {children}
        </Stack>
      </Container>
    </Box>
  )
}
