import React from 'react'
import { Box } from '@mui/material'

export const HeaderShadow: React.FC = () => (
  <Box
    sx={{
      left: 0,
      right: 0,
      bottom: 0,
      m: 'auto',
      height: 24,
      zIndex: -1,
      opacity: 0.48,
      borderRadius: '50%',
      position: 'absolute',
      width: `calc(100% - 48px)`,
      boxShadow: (muiTheme) => muiTheme.customShadows.z8,
    }}
  />
)
