import React from 'react'
import { Button, Stack, Typography, Grid } from '@mui/material'
import { PageLayout } from 'layouts/PageLayout/PageLayout'
import { PageSection } from 'components/page/PageSection/PageSection'
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui'
import { useSnackbar } from 'hooks/useSnackbar'
import { send } from '@emailjs/browser'
import { useTranslation } from 'react-i18next'
import { logFormSubmit } from 'helpers/analytics'
import { EMAIL_REGEXP } from 'constants/regex'

type PartnersJoinRequestFormValue = {
  companyName: string
  contactPersonName: string
  email: string
  phone: string
  website: string
  message: string
  name: string // Honeypot
}

export const PartnersJoinPage: React.FC = () => {
  const { t } = useTranslation('partners')
  const snackbarService = useSnackbar()

  const form = useForm<PartnersJoinRequestFormValue>()
  const handleSubmit = async (value: PartnersJoinRequestFormValue) => {
    // Honeypot check
    if (value.name) {
      snackbarService.showSuccess(t('join-partner-contact-sent-title'))
      form.reset()
      return
    }

    logFormSubmit('partners')

    try {
      await send('mockphish-contact-form', 'partner-request', value, {
        publicKey: process.env.REACT_APP_EMAILJS_TOKEN,
      })
      snackbarService.showSuccess(t('join-partner-contact-sent-title'))
      form.reset()
    } catch (e) {
      snackbarService.showError(t('join-partner-contact-sent-error'))
    }
  }

  return (
    <PageLayout title={t('join-partner-title')}>
      <PageSection>
        <Typography variant="body1">{t('join-partner-description')}</Typography>

        <FormContainer formContext={form} onSuccess={handleSubmit}>
          <Stack spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextFieldElement name="companyName" label={t('companyName')} fullWidth required />
              </Grid>
              <Grid item xs={6}>
                <TextFieldElement name="contactPersonName" label={t('contactPersonName')} fullWidth required />
              </Grid>
              <Grid item xs={6}>
                <TextFieldElement
                  name="email"
                  label={t('email')}
                  fullWidth
                  required
                  validation={{
                    pattern: EMAIL_REGEXP,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldElement name="phone" label={t('phone')} fullWidth required />
              </Grid>
              <Grid item xs={12}>
                <TextFieldElement name="website" label={t('websiteOptional')} fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextFieldElement multiline rows={3} name="message" label={t('join-partner-reason-title')} required fullWidth />
              </Grid>
            </Grid>

            <TextFieldElement
              name="name"
              autoComplete="off"
              placeholder="Your name here"
              tabIndex={-1}
              sx={{
                opacity: 0,
                position: 'absolute',
                top: 0,
                left: 0,
                height: 0,
                width: 0,
                zIndex: -1,
              }}
            />

            <Stack alignItems="flex-end">
              <Button type="submit" variant="contained" color="primary" disabled={!form.formState.isValid}>
                {t('send')}
              </Button>
            </Stack>
          </Stack>
        </FormContainer>
      </PageSection>
    </PageLayout>
  )
}
