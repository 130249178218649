import React, { PropsWithChildren } from 'react'
import { Box, Container, Stack, Typography } from '@mui/material'
import { HEADER } from 'theme/constants'
import { useSettings } from 'hooks/useSettings'

type PageLayoutProps = PropsWithChildren<{
  title: string
}>

export const PageLayout: React.FC<PageLayoutProps> = ({ title, children }) => {
  const { settings } = useSettings()
  const { themeMode } = settings

  return (
    <>
      <Box
        sx={{
          backgroundColor: themeMode === 'dark' ? '#184171' : '#a4c0ef',
          clipPath: 'polygon(0 0, 100% 0, 100% 96%, 0 100%)',
          pt: `${HEADER.H_DESKTOP}px`,
        }}
      >
        <Container>
          <Stack sx={{ height: { xs: '100px', sm: '200px' } }} justifyContent="center">
            <Typography variant="h3">{title}</Typography>
          </Stack>
        </Container>
      </Box>
      {children}
    </>
  )
}
