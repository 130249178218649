import { BlogPostData } from 'pages/blog/posts/types'

const post: BlogPostData = {
  id: 'mockphish-maandelijkse-update-augustus-2024',
  title: 'MockPhish Maandelijkse Update - Augustus 2024',
  date: '2024-08-01T00:00:00.000Z',
  readingTime: '5-7',
  showcaseImage: '/blog/mockphish-maandelijkse-update-augustus-2024/showcase.png',
  showcaseImageAlt: 'MockPhish Augustus 2024 Update',
  public: true,
  meta: {
    description:
      'Verken de laatste ontwikkelingen bij MockPhish in onze maandelijkse update voor augustus 2024. Leer meer over ons platform en de plannen voor nieuwe features.',
    keywords: ['phishing', 'bescherming tegen phishing', 'cyberbeveiliging'],
    canonicalTag: 'https://www.mockphish.io/blog/mockphish-maandelijkse-update-augustus-2024',
  },
  content: {
    nl: `
<h3>MockPhish Maandelijkse Update - Augstus 2024</h3>
<p>Welkom bij de eerste editie van onze maandelijkse update van MockPhish. Hier delen we zowel technische als bedrijfsmatige ontwikkelingen binnen ons project. Laten we de belangrijkste ontwikkelingen van deze maand bekijken.</p>

<h4>Hoofdontwikkelingen</h4>

<h6>Fundament van het Platform</h6>
<p>Sinds de start van ons project hebben we significante vooruitgang geboekt in de ontwikkeling van het MockPhish platform. Het platform kan nu geautomatiseerd phishing e-mails versturen en de reacties daarop analyseren. Een belangrijke toevoeging is ook de implementatie van een SCIM-koppeling, waardoor gebruikers automatisch toegevoegd kunnen worden vanuit Microsoft Entra ID, Google Workspace, en andere identity providers (IdPs). Deze kernfunctionaliteiten vormen de basis waarop we het MockPhish platform verder kunnen bouwen en innoveren.</p>

<h6>Voorbereidingen voor de Pilot</h6>
<p>We zijn druk bezig met de voorbereidingen voor onze aanstaande pilot, die op 1 oktober van start gaat. Tijdens deze pilot werken we samen met een selecte groep IT-dienstverleners en organisaties om een finale gebruikerstest uit te voeren en ervaringen van alle partijen te meten. <b>Wil je deel uitmaken van deze unieke kans om MockPhish in actie te zien en direct bij te dragen aan de ontwikkeling van ons product? Neem contact op en meld je aan!</b></p>

<h4>Wat staat er op de Planning?</h4>

<h6>Ontwikkeling van Specifieke Landingspagina’s</h6>
<p>Op basis van de ontvangen feedback en onze visie voor het MockPhish platform, zijn er plannen gemaakt om specifieke landingspagina's te ontwikkelen voor onze phishing simulaties. Deze pagina's zullen de realiteit van phishingaanvallen nabootsen om zo een effectieve testomgeving te bieden die het bewustzijnsniveau van medewerkers accuraat kan meten.</p>

<h6>Update van het Licentiesysteem</h6>
<p>Feedback heeft ook uitgewezen dat partijen graag meer flexibiliteit hebben met betrekking tot het licentiesysteem. We gaan werken aan een ontwerp voor een update van ons licentiesysteem om het flexibeler en meer toegesneden te maken op de behoeften van onze klantenkring. Dit vernieuwde systeem zal binnenkort worden geïmplementeerd.</p>
<hr/>
<h4>Blijf op de Hoogte</h4>
<p>Zorg dat je niets mist van onze toekomstige ontwikkelingen en updates door je in te schrijven voor onze nieuwsbrief. Blijf op de hoogte van de laatste verbeteringen en nieuwe functies bij MockPhish, zowel technisch als bedrijfsmatig.</p>
<p>Bedankt voor het lezen van onze update. We hopen dat je uitkijkt naar onze toekomstige ontwikkelingen net zoveel als wij. <b>Tot de volgende maand!</b></p>
  `,
    en: `If you see this then we messed up`,
  },
}

export default post
