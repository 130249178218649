import React from 'react'

import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import ReactMarkdown, { Options } from 'react-markdown'
import rehypeHighlight from 'rehype-highlight'

import Link from '@mui/material/Link'
import { Link as RouterLink } from 'react-router-dom'

import { Image } from 'components/image/Image'
import { StyledMarkdown } from './MarkdownStyles'
import { SxProps, Theme } from '@mui/material/styles'

export type MarkdownProps = Options & {
  sx?: SxProps<Theme>
}

const components = {
  img: ({ ...props }) => <Image alt={props.alt} ratio="16/9" sx={{ borderRadius: 2 }} {...props} />,
  a: ({ ...props }) => {
    const isHttp = props.href.includes('http')

    return isHttp ? (
      <Link target="_blank" rel="noopener" {...props} />
    ) : (
      <Link component={RouterLink} to={props.href} {...props}>
        {props.children}
      </Link>
    )
  },
}

export const Markdown = ({ sx, ...other }: MarkdownProps) => {
  return (
    <StyledMarkdown sx={sx}>
      <ReactMarkdown
        rehypePlugins={[rehypeRaw, rehypeHighlight, [remarkGfm, { singleTilde: false }]]}
        components={components}
        {...other}
      />
    </StyledMarkdown>
  )
}
