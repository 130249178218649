import React, { useState, useCallback } from 'react'

import { useActiveLink } from 'hooks/useActiveLink'

import { NavItem } from './NavItem'
import { NavListProps } from '../types'

export const NavList: React.FC<NavListProps> = ({ data }) => {
  const active = useActiveLink(data.path, !!data.children)

  const [openMenu, setOpenMenu] = useState(false)

  const handleToggleMenu = useCallback(() => {
    if (data.children) {
      setOpenMenu((prev) => !prev)
    }
  }, [data.children])

  return (
    <>
      <NavItem
        open={openMenu}
        onClick={handleToggleMenu}
        //
        title={data.title}
        path={data.path}
        icon={data.icon}
        //
        hasChild={!!data.children}
        externalLink={data.path.includes('http')}
        //
        active={active}
      />
    </>
  )
}
