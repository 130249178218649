import React from 'react'
import { Box, Container, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const HomeInfoBanner: React.FC = () => {
  const { t } = useTranslation('home')
  const renderInfoSection = (img: string, title: string, description: string, reverse = false) => (
    <Box
      sx={{
        background: (theme) => (reverse ? theme.palette.background.neutral : theme.palette.background.default),
        clipPath: 'polygon(0 0, 100% 0, 100% 96%, 0 100%)',
      }}
    >
      <Container>
        <Stack alignItems="center" justifyContent="center" direction={reverse ? 'row-reverse' : 'row'} sx={{ py: 5 }}>
          <Stack spacing={2} alignItems="flex-start">
            <Typography variant="h2">{title}</Typography>
            <Typography variant="body1">{description}</Typography>
          </Stack>
          <Box component="img" src={img} alt="Icon" sx={{ width: '15vw', display: { xs: 'none', md: 'block' } }} />
        </Stack>
      </Container>
    </Box>
  )

  return (
    <Box id="info">
      {renderInfoSection('/icons/icon-email-bomb.png', t('info-cyberThread-title'), t('info-cyberThread-description'))}
      {renderInfoSection('/icons/icon-email-alert.png', t('info-solution-title'), t('info-solution-description'), true)}
    </Box>
  )
}
