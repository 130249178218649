import React from 'react'

import { alpha, styled } from '@mui/material/styles'
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton'

import { NavItemStateProps } from '../types'

export const StyledNavItem: React.FC<ListItemButtonProps & { open?: boolean; active?: boolean }> = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'active',
})<NavItemStateProps>(({ open, active, theme }) => {
  const opened = open && !active

  return {
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    height: 48,
    ...(active && {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightSemiBold,
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.16),
      },
    }),
    ...(opened && {
      backgroundColor: theme.palette.action.hover,
    }),
  }
})
