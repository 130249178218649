import React from 'react'
import Stack from '@mui/material/Stack'

import { NavList } from './NavList'
import { NavProps } from '../types'

export const NavDesktop: React.FC<NavProps> = ({ data }) => {
  return (
    <Stack component="nav" direction="row" spacing={5} sx={{ mr: 2.5, height: 1 }}>
      {data.map((list) => (
        <NavList key={list.title} data={list} />
      ))}
    </Stack>
  )
}
