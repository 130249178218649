// ----------------------------------------------------------------------

export function createContrast() {
  const theme = {
    palette: {},
  }

  const components = {}

  return {
    ...theme,
    components,
  }
}
