import React from 'react'
import { Stack, Typography } from '@mui/material'

export const FooterBottom: React.FC = () => {
  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={2} sx={{ mt: 10 }}>
      <Typography variant="body2" sx={{ display: 'inline-block', textAlign: { xs: 'center', sm: 'unset' } }}>
        © {new Date().getFullYear()} MockPhish.io
      </Typography>
      {/*<Stack direction='row' justifyContent={{ xs: 'center', md: 'flex-start' }} spacing={2} sx={{ mt: { xs: 2, sm: 0 } }}>*/}
      {/*  <Link component={RouterLink} to='/terms-and-conditions' color='inherit' variant='body2'>*/}
      {/*    Algemene voorwaarden*/}
      {/*  </Link>*/}
      {/*  <Link component={RouterLink} to='/privacy-policy' color='inherit' variant='body2'>*/}
      {/*    Privacy Policy*/}
      {/*  </Link>*/}
      {/*</Stack>*/}
    </Stack>
  )
}
