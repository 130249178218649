import React from 'react'
import { useSettings } from 'hooks/useSettings'
import IconButton from '@mui/material/IconButton'
import { DarkMode, LightMode } from '@mui/icons-material'
import { Button } from '@mui/material'

type ThemeSwitcherProps = {
  iconOnly?: boolean
}

export const ThemeSwitcher: React.FC<ThemeSwitcherProps> = ({ iconOnly = true }) => {
  const { settings, changeThemeMode } = useSettings()
  const themeMode = settings.themeMode
  const switchTheme = () => changeThemeMode(themeMode === 'light' ? 'dark' : 'light')
  const icon = themeMode === 'light' ? <DarkMode /> : <LightMode />

  return iconOnly ? (
    <IconButton
      onClick={switchTheme}
      sx={{
        width: 40,
        height: 40,
      }}
    >
      {icon}
    </IconButton>
  ) : (
    <Button startIcon={icon} onClick={switchTheme}>
      {themeMode === 'light' ? 'Donkere modus' : 'Lichte modus'}
    </Button>
  )
}
