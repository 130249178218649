import React from 'react'
import { Stack, Container, Typography, Box, Button, Card, Alert } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSettings } from 'hooks/useSettings'
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui'
import { logFormSubmit } from 'helpers/analytics'
import { useSnackbar } from 'hooks/useSnackbar'
import { EMAIL_REGEXP } from 'constants/regex'

type ContactFormValue = {
  email: string
  name: string //honeypot
}

export const HomeNewsLetter: React.FC = () => {
  const { t } = useTranslation('home')
  const { settings } = useSettings()
  const { themeMode } = settings
  const snackbarService = useSnackbar()
  const [subscribeSuccess, setSubscribeSuccess] = React.useState(false)

  const form = useForm<ContactFormValue>()
  const handleSubmit = async (value: ContactFormValue) => {
    // Honeypot check
    if (value.name) {
      form.reset()
      setSubscribeSuccess(true)
      return
    }

    logFormSubmit('newsletter')

    try {
      const response = await fetch('https://connect.mailerlite.com/api/subscribers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_MAILERLITE_API_KEY!}`,
        },
        body: JSON.stringify({ email: value.email, groups: ['128289887407310071'] }),
      })
      if (response.ok) {
        form.reset()
        setSubscribeSuccess(true)
      }
    } catch (e) {
      snackbarService.showError(t('newsletter-subscribe-sent-error'))
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: themeMode === 'dark' ? '#184171' : '#a4c0ef',
      }}
    >
      <Container sx={{ py: '7rem' }}>
        <Stack alignItems="center" spacing={3}>
          <Typography variant="h2" textAlign="center">
            {t('newsletter-subscribe-title')}
          </Typography>
          <Typography variant="body1" textAlign="center" sx={{ fontSize: '1.1rem' }}>
            {t('newsletter-subscribe-description')}
          </Typography>
          {subscribeSuccess ? (
            <Alert color="success">{t('newsletter-subscribe-sent-success')}</Alert>
          ) : (
            <Card sx={{ p: 2 }}>
              <FormContainer formContext={form} onSuccess={handleSubmit}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <TextFieldElement
                    name="name"
                    autoComplete="off"
                    placeholder="Your name here"
                    tabIndex={-1}
                    sx={{
                      opacity: 0,
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      height: 0,
                      width: 0,
                      zIndex: -1,
                    }}
                  />
                  <TextFieldElement
                    name="email"
                    label={t('email')}
                    fullWidth
                    required
                    validation={{
                      pattern: EMAIL_REGEXP,
                    }}
                    sx={{
                      minWidth: {
                        xs: '100%',
                        sm: '25rem',
                      },
                    }}
                  />
                  <Button type="submit" size="large" variant="contained" color="primary" sx={{ px: 2 }}>
                    {t('subscribe')}
                  </Button>
                </Stack>
              </FormContainer>
              <Typography variant="body2" color="text.disabled" sx={{ px: 0.5, pt: 1 }}>
                Door in te schrijven op de nieuwsbrief ga je akkoord met onze{' '}
                <a href="/privacy-policy" target="_blank">
                  privacyvoorwaarden
                </a>
                . Je kan op elk moment uitschrijven.
              </Typography>
            </Card>
          )}
        </Stack>
      </Container>
    </Box>
  )
}
