import React, { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import { Menu } from '@mui/icons-material'

import { Box, Stack } from '@mui/material'
import { Logo } from 'components/logo/Logo'

import { NavList } from './NavList'
import { NavProps } from '../types'

import { ThemeSwitcher } from '../../components/ThemeSwticher/ThemeSwitcher'
import { GoToPortalButton } from '../../components/GoToPortalButton/GoToPortalButton'

export const NavMobile: React.FC<NavProps> = ({ data }) => {
  const { pathname } = useLocation()
  const [openMenu, setOpenMenu] = useState(false)

  const handleOpenMenu = useCallback(() => {
    setOpenMenu(true)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setOpenMenu(false)
  }, [])

  useEffect(() => {
    if (openMenu) {
      handleCloseMenu()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <>
      <IconButton onClick={handleOpenMenu} sx={{ ml: 1 }}>
        <Menu />
      </IconButton>

      <Drawer
        open={openMenu}
        onClose={handleCloseMenu}
        PaperProps={{
          sx: {
            width: 260,
            minHeight: '100%',
          },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
          <Logo sx={{ mx: 2.5, my: 3 }} />

          {data.map((list) => (
            <NavList key={list.title} data={list} />
          ))}

          <Box sx={{ flexGrow: 1 }}></Box>

          <Stack spacing={2} direction="column" sx={{ m: 1 }}>
            <GoToPortalButton />
            <Stack spacing={2} direction="row" justifyContent="space-evenly">
              <ThemeSwitcher iconOnly={false} />
              {/*<LanguagePopover iconOnly={false} />*/}
            </Stack>
          </Stack>
        </Box>
      </Drawer>
    </>
  )
}
