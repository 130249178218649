import React from 'react'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const GoToPortalButton: React.FC = () => {
  const { t } = useTranslation('header')
  return (
    <Button variant="contained" color="primary" target="_blank" rel="noopener" href="https://app.mockphish.io">
      {t('goToPortal')}
    </Button>
  )
}
