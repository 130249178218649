import React, { forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import Box from '@mui/material/Box'
import Link from '@mui/material/Link'

import { StyledNavItem } from './NavItemStyles'
import { NavItemProps } from '../types'
import { ExpandMore, KeyboardArrowRight } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

export const NavItem = forwardRef<HTMLDivElement, NavItemProps>(function NavItem(
  { title, path, icon, open, active, hasChild, externalLink, ...other },
  ref
) {
  const { t } = useTranslation('header')
  const renderContent = (
    <StyledNavItem ref={ref} open={open} active={active} {...other}>
      <Box component="span" sx={{ mr: 2, display: 'inline-flex' }}>
        {icon}
      </Box>

      <Box component="span" sx={{ flexGrow: 1 }}>
        {t(title)}
      </Box>

      {hasChild && (open ? <ExpandMore sx={{ fontSize: 16 }} /> : <KeyboardArrowRight sx={{ fontSize: 16 }} />)}
    </StyledNavItem>
  )

  if (hasChild) {
    return renderContent
  }

  if (externalLink)
    return (
      <Link href={path} target="_blank" rel="noopener" color="inherit" underline="none">
        {renderContent}
      </Link>
    )

  return (
    <Link component={RouterLink} to={path} color="inherit" underline="none">
      {renderContent}
    </Link>
  )
})
