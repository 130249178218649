import React, { useEffect } from 'react'

import { Outlet, useLocation } from 'react-router-dom'
import { Box } from '@mui/material'
import { Header } from './Header/Header'
import { Footer } from 'layouts/AppLayout/Footer/Footer'
import { GAPageTracker } from 'components/GAPageTracker/GAPageTracker'

export const AppLayout: React.FC = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      <Header />

      <Box component="main" sx={{ flexGrow: 1 }}>
        <Outlet />
      </Box>

      <Footer />

      <GAPageTracker />
    </>
  )
}
