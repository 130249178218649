import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { logPageView } from 'helpers/analytics'

export const GAPageTracker: React.FC = () => {
  const location = useLocation()

  useEffect(() => {
    logPageView(location.pathname + location.search)
  }, [location])

  return null
}
