import React from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

export const NotFound: React.FC = () => {
  const { t } = useTranslation('errors')
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Helmet>
        <title>Pagina niet gevonden | MockPhish</title>
      </Helmet>
      <Box
        component="img"
        src="/icon.png"
        alt="MockPhish Logo"
        sx={{
          mb: 3,
          width: 80,
          height: 80,
        }}
      />
      <Stack spacing={2}>
        <Typography variant="h4">{t('notFoundTitle')}</Typography>
        <Typography variant="body2">{t('notFoundDescription')}</Typography>
        <Box>
          <Button component={Link} to="/" replace color="primary" variant="contained" size="large">
            {t('backToStart')}
          </Button>
        </Box>
      </Stack>
    </Box>
  )
}
