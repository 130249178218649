import React from 'react'
import { useTranslation } from 'react-i18next'

// core (MUI)

export const LANGUAGE_OPTIONS = [
  {
    label: 'Nederlands',
    value: 'nl',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="1.34em" height="1em" viewBox="0 0 640 480">
        <path fill="#21468b" d="M0 0h640v480H0z" />
        <path fill="#fff" d="M0 0h640v320H0z" />
        <path fill="#ae1c28" d="M0 0h640v160H0z" />
      </svg>
    ),
  },
  {
    label: 'English',
    value: 'en',
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="1.34em" height="1em" viewBox="0 0 640 480">
        <path fill="#012169" d="M0 0h640v480H0z" />
        <path fill="#fff" d="m75 0l244 181L562 0h78v62L400 241l240 178v61h-80L320 301L81 480H0v-60l239-178L0 64V0z" />
        <path
          fill="#c8102e"
          d="m424 281l216 159v40L369 281zm-184 20l6 35L54 480H0zM640 0v3L391 191l2-44L590 0zM0 0l239 176h-60L0 42z"
        />
        <path fill="#fff" d="M241 0v480h160V0zM0 160v160h640V160z" />
        <path fill="#c8102e" d="M0 193v96h640v-96zM273 0v480h96V0z" />
      </svg>
    ),
  },
]

export const DEFAULT_LANGUAGE = LANGUAGE_OPTIONS[0] //Dutch

export const useLocales = () => {
  const { i18n } = useTranslation()

  // const currentLanguage = LANGUAGE_OPTIONS.find((language) => language.value === storedLanguage) || DEFAULT_LANGUAGE
  const currentLanguage = DEFAULT_LANGUAGE

  const changeLanguage = (newLanguage: string) => {
    localStorage.setItem('language', newLanguage)
    i18n.changeLanguage(newLanguage)
  }

  return {
    LANGUAGE_OPTIONS,
    currentLanguage,
    changeLanguage,
  }
}
