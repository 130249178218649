import React from 'react'
import { Box, Button, Stack, Typography, Grid } from '@mui/material'
import { PageLayout } from 'layouts/PageLayout/PageLayout'
import { PageSection } from 'components/page/PageSection/PageSection'
import { Business, Email } from '@mui/icons-material'
import { Logo } from 'components/logo/Logo'
import { send } from '@emailjs/browser'
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui'
import { useSnackbar } from 'hooks/useSnackbar'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { logFormSubmit } from 'helpers/analytics'
import { EMAIL_REGEXP } from 'constants/regex'

type ContactFormValue = {
  firstName: string
  lastName: string
  email: string
  phone: string
  name: string //honeypot
}

export const ContactPage: React.FC = () => {
  const { t } = useTranslation('contact')
  const form = useForm<ContactFormValue>()
  const snackbarService = useSnackbar()
  const renderContactRow = (icon: React.ReactNode, value: string) => (
    <Stack direction="row" spacing={2} alignItems="center">
      {icon}
      <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
        {value}
      </Typography>
    </Stack>
  )

  const handleSubmit = async (value: ContactFormValue) => {
    // Honeypot check
    if (value.name) {
      snackbarService.showSuccess(t('contact-sent-title'))
      form.reset()
      return
    }

    logFormSubmit('contact')

    try {
      await send('mockphish-contact-form', 'mockphish-contact-form', value, {
        publicKey: process.env.REACT_APP_EMAILJS_TOKEN,
      })
      snackbarService.showSuccess(t('contact-sent-title'))
      form.reset()
    } catch (e) {
      snackbarService.showError(t('contact-sent-error'))
    }
  }

  return (
    <PageLayout title={t('contact-title')}>
      <Helmet>
        <title>Contact | MockPhish</title>
        <meta
          name="description"
          content="Neem contact op met MockPhish voor meer informatie over onze phishing simulatie tools. Ons team staat klaar om je te helpen."
        />
        <meta name="keywords" content="contact, phishing simulatie, cybersecurity, MSP, MSSP, MockPhish" />
        <meta name="author" content="MockPhish" />
        <meta property="og:title" content="Contact | MockPhish" />
        <meta
          property="og:description"
          content="Neem contact op met MockPhish voor meer informatie over onze phishing simulatie tools. Ons team staat klaar om je te helpen."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.mockphish.io/contact" />
        <meta property="og:image" content="https://mockphish.io/logo-black.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact | MockPhish" />
        <meta
          name="twitter:description"
          content="Neem contact op met MockPhish voor meer informatie over onze phishing simulatie tools. Ons team staat klaar om je te helpen."
        />
        <meta name="twitter:url" content="https://www.mockphish.io/contact" />
        <meta name="twitter:image" content="https://mockphish.io/logo-black.png" />
      </Helmet>

      <PageSection>
        <Typography variant="body1">{t('contact-description')}</Typography>

        <Grid container spacing={5} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={2}>
              <Typography variant="h6">{t('contact-info')}</Typography>
              <Box>
                <Logo />
              </Box>

              {renderContactRow(<Business fontSize="large" />, t('contact-info-address'))}
              {/*{renderContactRow(<Phone fontSize='large' />, '+31681748172')}*/}
              {renderContactRow(<Email fontSize="large" />, t('contact-info-email'))}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormContainer formContext={form} onSuccess={handleSubmit}>
              <Stack spacing={2}>
                <Typography variant="h6">{t('contact-form-title')}</Typography>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextFieldElement name="firstName" label={t('firstName')} fullWidth required />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldElement name="lastName" label={t('lastName')} fullWidth required />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldElement name="companyName" label={t('companyName')} fullWidth required />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldElement
                      name="email"
                      label={t('email')}
                      validation={{
                        pattern: EMAIL_REGEXP,
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldElement name="phone" label={t('phone')} fullWidth required />
                  </Grid>
                </Grid>

                <TextFieldElement
                  name="name"
                  autoComplete="off"
                  placeholder="Your name here"
                  tabIndex={-1}
                  sx={{
                    opacity: 0,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: 0,
                    width: 0,
                    zIndex: -1,
                  }}
                />

                <Stack alignItems="flex-end">
                  <Button type="submit" variant="contained" color="primary" disabled={!form.formState.isValid}>
                    {t('send')}
                  </Button>
                </Stack>
              </Stack>
            </FormContainer>
          </Grid>
        </Grid>
      </PageSection>
    </PageLayout>
  )
}
