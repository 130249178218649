import React, { Context, createContext, PropsWithChildren, useContext } from 'react'

export const createProvider = <T,>(): {
  Provider: React.FC<
    PropsWithChildren<{
      value: T
    }>
  >
  useProvider: () => T
} => {
  let context: Context<T>

  const Provider: React.FC<
    PropsWithChildren<{
      value: T
    }>
  > = ({ value, children }): JSX.Element => {
    if (!context) {
      context = createContext<T>(value)
    }

    return <context.Provider value={value}>{children}</context.Provider>
  }

  const useProvider = () => useContext(context)

  return {
    Provider,
    useProvider,
  }
}
