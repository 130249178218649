import React from 'react'
import { Helmet } from 'react-helmet'

import { HomeHero } from './HomeHero'
import { HomeInfoBanner } from './HomeInfoBanner'
import { HomeReasons } from './HomeReasons'
import { HomeNewsLetter } from 'pages/home/HomeNewsLetter'

export const HomePage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>MockPhish | Maak uw medewerkers de beste verdediging</title>
        <meta
          name="description"
          content="MockPhish biedt superieure phishing simulatie tools voor MSP's en MSSP's. Maak uw medewerkers de beste verdediging met onze krachtige software."
        />
        <meta name="keywords" content="phishing simulatie, cybersecurity, MSP, MSSP, netwerkbeveiliging, MockPhish" />
        <meta name="author" content="MockPhish" />
        <meta property="og:title" content="MockPhish | Maak uw medewerkers de beste verdediging" />
        <meta
          property="og:description"
          content="MockPhish biedt superieure phishing simulatie tools voor MSP's en MSSP's. Maak uw medewerkers de beste verdediging met onze krachtige software."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.mockphish.io" />
        <meta property="og:image" content="https://mockphish.io/logo-black.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="MockPhish | Maak uw medewerkers de beste verdediging" />
        <meta
          name="twitter:description"
          content="MockPhish biedt superieure phishing simulatie tools voor MSP's en MSSP's. Maak uw medewerkers de beste verdediging met onze krachtige software."
        />
        <meta name="twitter:url" content="https://www.mockphish.io" />
        <meta name="twitter:image" content="https://mockphish.io/logo-black.png" />
      </Helmet>
      <HomeHero />
      <HomeInfoBanner />
      <HomeReasons />
      <HomeNewsLetter />
    </>
  )
}
