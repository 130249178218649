import ReactGA from 'react-ga4'

const initializeAnalytics = () => {
  if (!process.env.REACT_APP_GA_TAG) {
    console.warn('No GA_TAG SET, skippint initialization')
    return
  }
  ReactGA.initialize(process.env.REACT_APP_GA_TAG)
}

const logPageView = (page: string) => {
  ReactGA.send({ hitType: 'pageview', page })
}

const logFormSubmit = (form: string) => {
  ReactGA.event({
    category: 'Form',
    action: 'Submit',
    label: form,
  })
}

export { initializeAnalytics, logPageView, logFormSubmit }
