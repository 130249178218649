import common from './common.json'
import errors from './errors.json'
import footer from './footer.json'
import header from './header.json'
import home from './home.json'
import about from './about.json'
import partners from './partners.json'
import contact from './contact.json'
import blog from './blog.json'

export default {
  common,
  errors: { ...common, ...errors },
  footer: { ...common, ...footer },
  header: { ...common, ...header },
  home: { ...common, ...home },
  about: { ...common, ...about },
  partners: { ...common, ...partners },
  contact: { ...common, ...contact },
  blog: { ...common, ...blog },
}
