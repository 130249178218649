import React from 'react'
import { useLocation } from 'react-router-dom'

import Stack from '@mui/material/Stack'
import ListSubheader from '@mui/material/ListSubheader'

import { NavItem } from './NavItem'
import { NavSubListProps } from '../types'

export const NavSubList: React.FC<NavSubListProps> = ({ data, subheader, sx, ...other }) => {
  const { pathname } = useLocation()

  return (
    <Stack
      spacing={2}
      flexGrow={1}
      alignItems="flex-start"
      sx={{
        pb: 2,
        ...sx,
      }}
      {...other}
    >
      <ListSubheader
        disableSticky
        sx={{
          p: 0,
          typography: 'overline',
          fontSize: 11,
          color: 'text.primary',
        }}
      >
        {subheader}
      </ListSubheader>

      {data.map((item) => (
        <NavItem
          key={item.title}
          title={item.title}
          path={item.path}
          active={pathname === item.path || pathname === `${item.path}/`}
          subItem
        />
      ))}
    </Stack>
  )
}
