import { closeSnackbar, SnackbarProvider as NotistackProvider } from 'notistack'
import IconButton from '@mui/material/IconButton'
import { StyledIcon, StyledNotistack } from './SnackbarStyles'
import React, { PropsWithChildren } from 'react'
import { Check, Close, ErrorOutline, Info, WarningAmber } from '@mui/icons-material'

export const SnackbarProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <NotistackProvider
    maxSnack={5}
    preventDuplicate
    autoHideDuration={3000}
    variant="success" // Set default variant
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    iconVariant={{
      info: (
        <StyledIcon color="info">
          <Info sx={{ fontSize: 24 }} />
        </StyledIcon>
      ),
      success: (
        <StyledIcon color="success">
          <Check sx={{ fontSize: 24 }} />
        </StyledIcon>
      ),
      warning: (
        <StyledIcon color="warning">
          <WarningAmber sx={{ fontSize: 24 }} />
        </StyledIcon>
      ),
      error: (
        <StyledIcon color="error">
          <ErrorOutline sx={{ fontSize: 24 }} />
        </StyledIcon>
      ),
    }}
    Components={{
      default: StyledNotistack,
      info: StyledNotistack,
      success: StyledNotistack,
      warning: StyledNotistack,
      error: StyledNotistack,
    }}
    // with close as default
    action={(snackbarId: string | number) => (
      <IconButton size="small" onClick={() => closeSnackbar(snackbarId)} sx={{ p: 0.5 }}>
        <Close sx={{ fontSize: 24 }} />
      </IconButton>
    )}
  >
    {children}
  </NotistackProvider>
)
