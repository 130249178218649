type ApplicationStorage = {
  get: (key: string) => unknown
  set: (key: string, value: unknown) => void
  delete: (key: string) => void
  clear: () => void
}

const createStorage = (storage: Storage): ApplicationStorage => ({
  get: (key: string) => {
    try {
      const result = storage.getItem(key)
      return JSON.parse(result!)
    } catch (error) {
      console.error(error)
    }
  },
  set: (key: string, value: unknown) => {
    try {
      storage.setItem(key, JSON.stringify(value))
    } catch (error) {
      console.error(error)
    }
  },
  delete: (key: string) => {
    try {
      storage.removeItem(key)
    } catch (error) {
      console.error(error)
    }
  },
  clear: () => storage.clear(),
})

export const sessionStorage = createStorage(window.sessionStorage)
export const localStorage = createStorage(window.localStorage)
