import React from 'react'
import { Grid, Stack, Container, Typography, Card } from '@mui/material'
import { AutoMode, TextSnippet, TipsAndUpdates, MultilineChart, ImportantDevices, GridView } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

export const HomeReasons: React.FC = () => {
  const { t } = useTranslation('home')
  const renderItem = (icon: React.ReactNode, title: string, description: string) => (
    <Grid item xs={12} md={6} lg={4} key={title}>
      <Card sx={{ p: 5, height: '100%' }}>
        <Stack spacing={2}>
          {icon}
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body1" color="text.secondary">
            {description}
          </Typography>
        </Stack>
      </Card>
    </Grid>
  )

  const reasons = [
    {
      icon: <AutoMode sx={{ fontSize: 40 }} color="primary" />,
      title: t('reason-1-automation-title'),
      description: t('reason-1-automation-description'),
    },
    {
      icon: <TextSnippet sx={{ fontSize: 40 }} color="primary" />,
      title: t('reason-2-content-title'),
      description: t('reason-2-content-description'),
    },
    {
      icon: <TipsAndUpdates sx={{ fontSize: 40 }} color="primary" />,
      title: t('reason-3-microLearning-title'),
      description: t('reason-3-microLearning-description'),
    },
    {
      icon: <MultilineChart sx={{ fontSize: 40 }} color="primary" />,
      title: t('reason-4-reports-title'),
      description: t('reason-4-reports-description'),
    },
    {
      icon: <ImportantDevices sx={{ fontSize: 40 }} color="primary" />,
      title: t('reason-5-integration-title'),
      description: t('reason-5-integration-description'),
    },
    {
      icon: <GridView sx={{ fontSize: 40 }} color="primary" />,
      title: t('reason-6-ease-of-use-title'),
      description: t('reason-6-ease-of-use-description'),
    },
  ]

  return (
    <Container sx={{ py: 5 }}>
      <Stack spacing={4}>
        <Typography variant="h2" textAlign="center">
          {t('reasons-title')}
        </Typography>
        <Grid container spacing={2}>
          {reasons.map((reason) => renderItem(reason.icon, reason.title, reason.description))}
        </Grid>
      </Stack>
    </Container>
  )
}
