import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { AppBar, Box, Container, Stack, Toolbar } from '@mui/material'

import { HEADER } from 'theme/constants'
import { bgBlur } from 'theme/css'
import { useOffsetTop } from 'hooks/useOffsetTop'
import { useResponsive } from 'hooks/useResponsive'

import { ThemeSwitcher } from './components/ThemeSwticher/ThemeSwitcher'
import { HeaderShadow } from './components/HeaderShadow/HeaderShadow'
import { NavDesktop } from './nav/desktop'
import { NavMobile } from './nav/mobile'
import { navConfig } from './headerNavConfig'
import { Logo } from 'components/logo/Logo'
import { GoToPortalButton } from './components/GoToPortalButton/GoToPortalButton'

export const Header: React.FC = () => {
  const theme = useTheme()
  const mdUp = useResponsive('up', 'md')
  const offsetTop = useOffsetTop(HEADER.H_DESKTOP)

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />
          {mdUp && (
            <>
              <NavDesktop data={navConfig} />
              <GoToPortalButton />
            </>
          )}
          <Stack alignItems="center" direction={{ xs: 'row', md: 'row-reverse' }}>
            {!mdUp && <NavMobile data={navConfig} />}
          </Stack>
        </Container>
      </Toolbar>
      <Box sx={{ position: 'absolute', top: 12, right: 20, display: { xs: 'none', xl: 'block' } }}>
        <ThemeSwitcher />
        {/*<LanguagePopover />*/}
      </Box>
      {offsetTop && <HeaderShadow />}
    </AppBar>
  )
}
