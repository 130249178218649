import React from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

import { Markdown } from 'components/markdown/Markdown'
import { ArrowBack } from '@mui/icons-material'
import { Stack, Container, Button, Box, Typography } from '@mui/material'
import { HEADER } from 'theme/constants'
import { Image } from 'components/image/Image'
import { useNavigate, useParams } from 'react-router'
import { formatDate } from 'util/dateUtil'

import posts from './posts'
import { useLocales } from 'i18n/useLocales'
import { NewsLetterSubscribeForm } from 'components/NewsLetterSubscribeForm/NewsLetterSubscribeForm'

export const BlogPost: React.FC = () => {
  const { t } = useTranslation('blog')
  const navigate = useNavigate()
  const { postId } = useParams()

  const { currentLanguage } = useLocales()

  const post = posts.filter((p) => p.public).find((p) => p.id === postId!)
  if (!post) {
    throw Error('No Post Found')
  }

  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
  // @ts-ignore
  const content = post.content[currentLanguage.value]
  if (!content) {
    throw Error('No Content Found')
  }

  return (
    <Box sx={{ pt: `${HEADER.H_DESKTOP}px` }}>
      <Helmet>
        <title>{post.title} | Blog | MockPhish</title>
        <meta name="description" content={post.meta.description} />
        <meta name="keywords" content={post.meta.keywords.join(',')} />
        <meta name="author" content="MockPhish" />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.meta.description} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://www.mockphish.io/blog/${post.id}`} />
        <meta property="og:image" content={`https://www.mockphish.io${post.showcaseImage}`} /> {/* Assuming you have an image */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.meta.description} />
        <meta name="twitter:url" content={`https://www.mockphish.io/blog/${post.id}`} />
        <meta name="twitter:image" content={`https://www.mockphish.io${post.showcaseImage}`} />
      </Helmet>

      <Container>
        <Stack sx={{ mb: 2 }} alignItems="flex-start">
          <Button variant="text" startIcon={<ArrowBack />} onClick={() => navigate('/blog')}>
            {t('backToOverview')}
          </Button>
        </Stack>
        <Box
          sx={{
            maxWidth: 850,
            mx: 'auto',
            mt: { xs: 5 },
          }}
        >
          <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
            <Typography variant="body1" color="text.secondary">
              {t('postDate')}: {formatDate(post.date)}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {t('readingTime')}: {post.readingTime} min
            </Typography>
          </Stack>
          <Image src={post.showcaseImage} alt={post.showcaseImageAlt} ratio="16/9" sx={{ borderRadius: 2 }} />
          <Markdown>{content}</Markdown>
          <NewsLetterSubscribeForm />
        </Box>
      </Container>
    </Box>
  )
}
